<template>
  <div class="page-container">
    <div class="banner-content">
      <div class="banner">
        <img :src="bannerUrl" alt>
      </div>
      <div class="page-title-content">
        <div class="page-title">
          <div class="first-title">应用领域一站式解决方案</div>
          <div class="two-title">使用范围广泛</div>
        </div>
      </div>
    </div>
    <div class="page-content-div">
      <div class="page-content">
        <div class="lingyu-content">
          <div class="lingyu-left">
            <p class="lingyu-left-title">应用范围</p>
            <div class="type-content">
              <div
                v-for="(item, index) in typeList"
                :key="index"
                :class="{active: activeType==item.value}"
                @click="changeActiveType(item.value)"
                class="type-item"
              >{{item.name}}</div>
            </div>
          </div>
          <div class="lingyu-right">
            <div class="product-content">
              <div class="product-item" v-if="(!sourceType || sourceType != 2) && showArray[1]">
                <div class="product-item-left">
                  <div class="product-img-content">
                    <!--<img class="video-img" src="../../assets/image/one.png" alt>-->
                    <img class="video-img" :src="showArray[1].prodImg" alt>
                    <div
                      class="modal-content"
                      v-if="showArray[1].prodVideo"
                      @click="changeVideoShow(true, showArray[1].prodVideo)"
                    >
                      <img src="../../assets/svg/guanwang_icon_bofang.svg" alt="播放按钮">
                    </div>
                  </div>
                </div>
                <div class="product-item-right">
                  <p class="title">{{showArray[1].prodName}}</p>
                  <div class="detail" v-html="showArray[1].prodContent"></div>
                  <div class="detail" v-if="sourceType" v-html="showArray[1].prodDescription"></div>
                  <div class="detail" v-if="sourceType" v-html="showArray[1].remark"></div>

                  <div class="fangan-content">
                    <template v-for="(item, index) in showArray[1].prodSolutions">
                      <router-link
                              :to="{path:'/application-area/solution', query: {activeType: activeType,
                               packageType: 1,
                               fangAnIndex: index,
                               prodId: showArray[1].prodId}}"
                      >
                        <div class="fangan">
                          <span>解决方案 <span v-if="showArray[1].prodSolutions.length>1">{{index + 1}}</span></span>
                          <img
                                  class="right-img"
                                  src="../../assets/svg/guanwang_icon_jiantou_blue.svg"
                                  alt
                          >
                        </div>
                      </router-link>
                    </template>
                    <router-link
                      :to="{path: '/customized', query: {packageType: 1, areaType: typeListObj[activeType-1]}}"
                    >
                      <div class="dingzhi">
                        <span>立即定制</span>
                        <img
                          class="right-img"
                          src="../../assets/svg/guanwang_icon_jiantou_white.svg"
                          alt
                        >
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="product-item" v-if="(!sourceType || sourceType == 2) && !isHiden && showArray[2]">
                <div class="product-item-left">
                  <div class="product-img-content">
                    <!--<img class="video-img" src="../../assets/image/two.png" alt>-->
                    <img class="video-img" :src="showArray[2].prodImg" alt>

                    <div
                      class="modal-content"
                      v-if="showArray[2].prodVideo"
                      @click="changeVideoShow(true, showArray[2].prodVideo)"
                    >
                      <img src="../../assets/svg/guanwang_icon_bofang.svg" alt="播放按钮">
                    </div>
                  </div>
                </div>
                <div class="product-item-right">
                  <p class="title">{{showArray[2].prodName}}</p>
                  <div class="detail" v-html="showArray[2].prodContent"></div>
                  <div class="detail" v-if="sourceType" v-html="showArray[2].prodDescription"></div>
                  <div class="detail" v-if="sourceType" v-html="showArray[2].remark"></div>
                  <div class="fangan-content">
                    <template v-for="(item, index) in showArray[2].prodSolutions">
                      <router-link
                              :to="{path:'/application-area/solution', query: {activeType: activeType,
                              packageType: 2,
                              fangAnIndex: index,
                              prodId: showArray[2].prodId}}"
                      >
                        <div class="fangan">
                          <span>解决方案 <span v-if="showArray[2].prodSolutions.length>1">{{index + 1}}</span></span>
                          <img
                                  class="right-img"
                                  src="../../assets/svg/guanwang_icon_jiantou_blue.svg"
                                  alt
                          >
                        </div>
                      </router-link>

                    </template>

                    <router-link
                      :to="{path: '/customized', query: {packageType: 2, areaType: typeListObj[activeType-1]}}"
                    >
                      <div class="dingzhi">
                        <span>立即定制</span>
                        <img
                          class="right-img"
                          src="../../assets/svg/guanwang_icon_jiantou_white.svg"
                          alt
                        >
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="video_box" v-if="isShow">
      <div class="video-div">
        <i class="el-icon-circle-close" @click="closeVideoShow()"></i>
        <video
          :src="vedioData"
          controls="controls"
          width="760px"
          height="580px"
          x5-video-player-type="h5-page"
          playsinline="true"
          nofullscreen
        ></video>
      </div>
    </div>
    <div class="empty"></div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      typeList: [
        { name: "化工", value: 1 },
        { name: "塑料", value: 2 },
        { name: "建材", value: 3 }
        /*{name: '农肥', value: 4},
                    {name: '饲料', value: 5},
                    {name: '食品', value: 6},
                    {name: '其他用途', value: 7},*/
      ],
      typeListObj: {
        1: "化工",
        2: "塑料",
        3: "建材",
        4: "农肥",
        5: "饲料",
        6: "食品",
        7: "其他用途"
      },
      activeType: 1,
      isShow: false, //视频播放器显示隐藏
        vedioData: null,
      isHiden: false, //阀口袋在农肥，饲料，食品领域隐藏
      sourceType: null,
      PEareaInfoList: [
        {
          name: "化工",
          isHasVideo: false,
          contain:
            "PE重包装袋符合危险化学品包装资质。当需方采用本产品作为出口危险化学品的包装使用时，依照国家《SNT0370.1-2009<出口危险货物包装检验规程 第一部分>》，供方将会提供此批次包装的《出入境货物包装性能检验结果单》",
          discription:
            "危险化学品包装申报流程解决方案：让需方在使用供方包装产品前获得包装资质证明文件，为需方的产品生产流程提升效率。技术服务（插入网站《技术服务》栏目超链接）当中确认了环境与场景后，即为供方排定包装容器标记及批号，并在确认印刷设计版面方案当中与供方确认该内容后报检，印刷制版与产品生产同时进行。依照合同制定的交货时间内即可完成危险化学品包装的性能检测，争取发货之前便可令需方完成其生产计划中的危险化学品包装出口报关流程。危包化学原料技术解决方案：专为危险化学品II类 III类包装性能配方，满足粉体、固体、颗粒物料性质和毒性、酸性腐蚀性、碱性腐蚀性、氧化性等性质的危险化学品包装需求。更广泛应用于化学助剂、除草剂、化工原料、电镀料、阻燃料等化工领域的包装产品使用当中。",
          remark: ""
        },
        {
          name: "塑料",
          isHasVideo: false,
          contain:
            "早在PE重包装袋被作为FFS袋被石油塑料化工公司发明出来作为快速制袋（Film）——装料（Fill）——封口（Seal）的自动化机械包装时，具有特殊性质的塑料、塑料加工助剂便和本产品具有着共同提升性质性能的良性关系。",
          discription:
            "早在PE重包装袋被作为FFS袋被石油塑料化工公司发明出来作为快速制袋（Film）——装料（Fill）——封口（Seal）的自动化机械包装时，具有特殊性质的塑料、塑料加工助剂便和本产品具有着共同提升性质性能的良性关系。为了包装的性能提升，具有特殊性质的塑料和塑料加工助剂被研究出来，为了使得这些具有特殊性质的塑料和助剂在运输过程中保证质量和提升物流效率，包装的性能不断研究而提升。 \r\n特殊性质物料抗静电需求解决方案：部分粉料或者颗粒性质的塑料、塑料助剂在自动化设备灌装当中会产生静电，致使物料之间粘着吸附于其包装的内壁，影响了封口质量，在产品运输、二次运输、经销、分销当中逐渐对产品和包装性质外观产生影响。PE重包装袋在吹塑工艺中添加抗静电性能配方，解决此类隐患问题。\r\n 特殊性质物料抗氧化需求解决方案：依照使用环境与场景，物料的包装需要更强的抗UV和抗氧化性，以保证所包装的物料在使用前能被保护。PE重包装袋在吹塑工艺中添加抗氧化性能配方，解决此类隐患问题。",
          remark:
            "（注：抗静电与抗氧化配方的比例视产品物料性质决定，请需方依照技术服务与供方确认物料性质、使用环境与场景后供方根据经验调整包装参数。验收标准会与合同同时确定，践行风险共担原则）"
        },
        {
          name: "建材",
          isHasVideo: false,
          contain:
            "砂浆、涂料、混凝土，建材领域的包装需求量一向庞大。传统的大多采用塑料编织袋、彩印复合编织袋或者牛皮纸袋、纸塑复合袋。PE重包装袋通过提高包装效率，降低劳动成本，超强膜配方能以同比重更薄的厚度达成更强的膜面强度与载重能力。",
          discription:
            "成本优势与载重强度解决方案：PE重包装袋配套相应自动灌装设备，提高包装效率，降低劳动强度。由于采用自动化包装形式，包装速度比传统编织袋包装提高效率3～5倍，操作工人数量减少75%左右。PE重包装袋工艺最薄可达10丝（0.1毫米）在此厚度内一包袋子即可承受25kg的物料，另外选择添加超强膜配方，增加同比重袋子强度。",
          remark:
            "（注：添加超强膜配方后的袋子硬度和强度提升，但延展性将会下降。需方可事先联系并索样，后决定是否添加。依照技术服确认物料性质、使用环境与场景后供方根据经验调整包装参数。验收标准会与合同同时确定，践行风险共担原则）"
        },
        {
          name: "农肥",
          isHasVideo: false,
          contain:
            "PE重包装袋由于其外观整洁、膜面印刷效果好，在农肥领域日趋受厂商欢迎，并主要被用来包装水溶肥和营养土。自温州丹业包装创立以来，积累了丰富的农肥比重配方所需的包装成本，形成价格优势。并针对农肥使用环境与场景中避光、透气性需求，开发了成熟的解决方案。",
          discription:
            "成本优势解决方案:由于农肥的比重密度相对较轻，袋子的尺寸要求较为宽松，但不同厂家不同的农肥产品，使用的配方亦有不同比重，导致了农肥产品并无标准尺寸。温州丹业包装有限公司自成立以来，在和农肥市场厂商的合作中逐渐积累了丰富的尺寸和配方经验，尤其针对农肥市场的水溶肥和营养土，能最大限度的为需方厂商带来成本价格优惠，形成成本优势。物料避光等性质解决方案：在与需方厂商的多年合作中，温州丹业包装认识到了农肥市场的独特性，尤其在物流、二次物流、经销、分销的过程中对包装的性能要求。为了保证物料的质量尤其是营养土的活性，通过定制膜面颜色生产黑色等深色膜面保证了物料的避光，针对透气性需要更新定制了相应制袋工艺。丰富的经验与积累的口碑，为需方厂商所广泛好评。",
          remark:
            "（注：定制颜色膜供方要有一定的起订量要求，需方请事先与供方沟通安排生产。由于农肥不同比重配方仍有密度差异，需方可事先联系并索样。依照技术服务与供方确认物料性质、使用环境与场景后供方根据经验调整包装参数。验收标准会与合同同时确定，践行风险共担原则）"
        },
        {
          name: "饲料",
          isHasVideo: false,
          contain:
            "产品在面世的过程中，其功能为市场所不断发掘。发酵饲料市场的需方厂商便是这么主动发掘到PE重包装袋这款产品的，从最基础的密封、防潮需求出发，到定制配方和透气速率定制，PE重包装袋的性能不断被应用于发酵饲料领域。",
          discription:
            "饲料透气性、发酵密封需求解决方案：作为在人工控制条件下，微生物通过自身的代谢活动，将植物性、动物性和矿物性物质中的抗营养因子分解或转化，产生更能被牲畜采食消化、吸收的养分更高且无毒害作用的饲料原料。PE重包装袋最开始是由于其密封性、防潮性为发酵饲料市场所认识，直接将饲料原料与菌种灌装于PE重包装袋内，物流仓储过程即为发酵过程。饲料在发酵过程中会产生气体而鼓包，单向排气阀便被应用于制袋流程中。针对不同饲料菌种选配排气阀，根据仓储物流环境定制特殊的包装袋性能配方，温州丹业包装在发酵饲料领域具有丰富的合作经验。",
          remark:
            "（注：不同菌种与饲料原料，袋子排气速率不同，比重也有所不同。需方可请事先与供方沟通索取样品，特针对发酵饲料市场采取减小起订量打样，验收标准会与合同同时确定，践行风险共担原则。待到菌种种类与包装排气速率都得以无误后，供方方可为需方采取大数量订单。）"
        },
        {
          name: "食品",
          isHasVideo: false,
          contain:
            "根据《GB9688-1988 食品包装用聚丙烯成型品卫生标准》温州丹业包装生产的PE重包装袋通过了《GB-T_5009.60-2003_食品包装用聚乙烯、聚苯乙烯、聚丙烯成型品卫生标准的分析方法》。产品已应用于食品添加剂、玉米粒等产品的包装领域。",
          discription:
            "食包质量需求解决方案：车间保证清洁、卫生、干燥、无异味、防潮、防雨。由于本公司食品用和非食品用原辅料——溶剂、油墨共用，故供方公司所用的溶剂、油墨皆按照食品级的要求进货。制袋完毕后于食品级车间装袋、装箱，需方可要求供方公司以需方所需要的食品运输物流标准装运，运输环境严禁与有毒有害、有异味、易污染物质混运，防止与非清真产品、转基因产品交叉污染。",
          remark: ""
        },
        {
          name: "其他",
          isHasVideo: false,
          contain:
            "除了用在各领域的物料包装以外，PE重包装袋的性能和工艺还被市场发掘了其他用途。",
          discription:
            "物流运输领域和成本优势需求解决方案：由于PE重包装袋的密封性、防潮性、防水性和防污性能优越，可露天堆放便于储存，可码垛打托盘运输，许多小包装产品在物流周转途中选择PE重包装袋作为二层保护。配备华联机械集团的手动封口机，封口整齐不易滑包，PE重包装袋工艺最薄可达10丝（0.1毫米）在此厚度内一包袋子即可承受25kg的物料，节约了单条袋子成本，形成了成本优势。",
          remark: ""
        }
      ],
        bannerUrl: '',
        prodObj: {},
        showArray: {}
    };
  },
  mounted() {
      const banners = JSON.parse(localStorage.getItem('banners'));
      banners ? this.bannerUrl = banners[4]['imgUrl'] : this.bannerUrl = this.$url.backImg[4];

      // 标识是否从PE阀口袋来的
    // 那阀口袋还没用在农肥、饲料、食品上，因为是胶粘的，密封性没那么好
    this.sourceType = this.$route.query.type;
    if (!this.sourceType || this.sourceType != 2) {
      this.typeList.push(
        ...[
          { name: "农肥", value: 4 },
          { name: "饲料", value: 5 },
          { name: "食品", value: 6 }
        ]
      );
    }
    this.typeList.push({ name: "其他用途", value: 7 });
    const areaType = this.$route.query.areaType;
    if (areaType) {
      this.activeType = areaType;
      this.changeActiveType(areaType);
    } else {
      this.activeType = 1;
    }
    this.getList();
  },

  methods: {
      getList() {
          const _this = this;
          let url = this.$url.getBackendApiUrl(`/api/prod/list`);
          url = this.$url.addQueryString(url, "pageNum", 1);
          url = this.$url.addQueryString(url, "pageSize", 99);

          this.$http.post(url, {}).then(
              res => {
                  if (res.data.code == 200) {
                      res.data.data.list.forEach(item => {
                          console.log(item);
                          item.prodContent = _this.getBr(item.prodContent);
                          item.prodDescription = _this.getBr(item.prodDescription);
                          item.remark = _this.getBr(item.remark);

                          if (_this.prodObj[item.prodFunction]) {
                              _this.prodObj[item.prodFunction][item.prodType * 1] = item;
                          } else {
                              _this.prodObj[item.prodFunction] = {};
                              _this.prodObj[item.prodFunction][item.prodType * 1] = item;
                          }
                      });
                      _this.showArray = _this.prodObj[_this.activeType];


                  }
              },
              () => {
              }
          );
      },
      // textarea填入的换行以及空格，页面显示转换方法
      getBr(data) {
          if (data) {
              return data.replace(/\n/g,'<br/>');
          }
      },
    changeVideoShow(item, data) {
      this.isShow = item;
      console.log(data);
      this.vedioData = data;
    },

    changeActiveType(item) {
      this.activeType = item;
      this.showArray = this.prodObj[this.activeType];

        if (
        this.activeType == 4 ||
        this.activeType == 5 ||
        this.activeType == 6
      ) {
        this.isHiden = true;
      } else {
        this.isHiden = false;
      }
    },

    closeVideoShow() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.video_box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  .video-div {
    position: relative;
    i {
      position: absolute;
      top: -30px;
      right: -30px;
      z-index: 999;
      font-size: 25px;
      color: #fff;
      cursor: pointer;
    }
    video {
      outline: none;
    }
  }
}
.page-container {
  font-family: PingFangSC-Regular;
}
.page-content-div {
  display: flex;
  justify-content: center;
  width: 100%;
  .page-content {
    width: 1200px;
    .lingyu-content {
      display: flex;
      justify-content: space-between;
      .lingyu-left {
        width: 180px;
        border-left: 1px solid rgba(233, 233, 238, 1);
        border-right: 1px solid rgba(233, 233, 238, 1);
        border-bottom: 1px solid rgba(233, 233, 238, 1);
        padding: 20px 0;
        .lingyu-left-title {
          color: rgba(5, 6, 16, 100);
          font-size: 22px;
          padding-left: 16px;
          padding-bottom: 10px;
          font-family: PingFangSC-Medium;
        }
        .type-content {
          .type-item {
            color: rgba(5, 6, 16, 100);
            font-size: 16px;
            font-family: PingFangSC-Medium;
            padding-left: 24px;
            height: 45px;
            line-height: 45px;
            margin-top: 15px;
            cursor: pointer;
            &:hover {
              color: rgba(57, 63, 227, 100);
              background: linear-gradient(90deg, #d8d9ff, rgba(57, 63, 227, 0));
            }
          }
          .active {
            color: rgba(57, 63, 227, 100);
            background: linear-gradient(90deg, #d8d9ff, rgba(57, 63, 227, 0));
            position: relative;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 3px;
              background-color: rgba(57, 63, 227, 1);
            }
          }
        }
      }
    }
  }
}

.lingyu-right {
  margin-left: 24px;
  flex: 1;
}
.product-content {
  width: 100%;
  .product-item {
    display: flex;
    margin-top: 50px;
    margin-bottom: 20px;
    .product-item-left {
      width: 282px;
      .product-img-content {
        width: 280px;
        height: 280px;
        border: 1px solid rgba(233, 233, 238, 1);
        position: relative;
        text-align: center;
        background: url("../../assets/shuiyin/fangan.png") no-repeat;
        background-position: 140px 140px;
        background-size: 80%;
        img.video-img {
          height: 90%;
          width: 90%;
          margin-top: 5%;
          object-fit: contain;
          object-position: center;
        }
        .modal-content {
          position: absolute;
          opacity: 0.41;
          background-color: rgba(11, 16, 50, 1);
          cursor: pointer;
          /*width: 100%;*/
          /*height: 100%;*/
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
    .product-item-right {
      flex: 1;
      margin-left: 40px;
      .title {
        color: rgba(5, 6, 16, 100);
        font-size: 24px;
        font-family: PingFangSC-Semibold;
      }
      .detail {
        line-height: 28px;
        color: rgba(114, 114, 122, 100);
        font-size: 16px;
        margin-top: 20px;
      }

      .fangan-content {
        display: flex;
        margin-top: 24px;
        .fangan {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid rgba(233, 233, 238, 1);
          height: 44px;
          padding: 0 20px;
          color: rgba(57, 63, 227, 100);
          font-size: 16px;
          margin-right: 10px;
          &:hover {
            border-color: rgba(57, 63, 227, 1);
          }
        }
        .dingzhi {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid rgba(233, 233, 238, 1);
          height: 44px;
          color: #fff;
          font-size: 16px;
          background: #393fe3;
          padding-left: 20px;
          padding-right: 8px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            right: -16px;
            top: 0;
            width: 0;
            height: 0;
            border: 22px solid;
            border-width: 22px 0 22px 16px;
            border-color: transparent transparent transparent #393fe3;
          }
          &:hover {
            background: #262aa4;
          }
          &:hover::before {
            border-color: transparent transparent transparent #262aa4;
          }
        }
      }
    }
  }
}
.page-title {
  margin-bottom: -5px;
}

.empty {
  width: 100%;
  height: 100px;
}
</style>